@import "variables.css";

/* Header */
/* .secondary__header-container.testimonial__subheader {
}

.secondary__header.testimonial__secondary__header {
  color: transparent;
  text-shadow: 0 0 0 rgba(0, 253, 240, 0.97);
}

.tertiary__header.testimonial__tertiary__header {
  color: transparent;
  text-shadow: 0 0 0 rgba(0, 253, 240, 0.97);
} */
/* End of Header */

.testimonial-section {
  width: 100%;
  height: 50rem;
  position: relative;
}

.testimonial__background__image-container {
  overflow: hidden;
}

.testimonial__background__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  max-height: 100%;
  object-fit: cover;
}

.testimonial__card-container {
  width: 30rem;
  height: 40rem;
  padding: 4.8rem;
  box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.5);
  background-color: var(--background-color-light);
  border-radius: 2rem;
}

.testimonial__person {
  max-width: 24rem;
  display: flex;
  justify-content: baseline;
  align-items: center;
  padding: 1.2rem;
}

.testimonial__image-container {
  max-width: 8rem;
  max-height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 0;
}

.testimonial__image {
  width: 100%;
  object-fit: cover;
}

.testimonial__content-profession {
  display: flex;
  flex-direction: column;
}

.testimonial__name {
  font-size: 1.8rem;
  font-weight: bold;
  margin-left: 0;
}

.testimonial__work {
  font-size: 1.2rem;
  margin-left: 0;
}

.testimonial__comment {
  font-size: 1.7rem;
}

.quote__icon {
  font-size: 4rem;
  color: var(--primary-color);
}

/* Slider */
.testimonial {
  padding-top: 4.8rem;
  display: grid;
  justify-content: center;
  align-self: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2.4rem;
}
