.testimonial-section {
  width: 100%;
  height: 50rem;
  position: relative;
}

.testimonial__background__image-container {
  overflow: hidden;
}

.testimonial__background__image {
  z-index: -1;
  object-fit: cover;
  width: 100vw;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial__card-container {
  background-color: var(--background-color-light);
  border-radius: 2rem;
  width: 30rem;
  height: 40rem;
  padding: 4.8rem;
  box-shadow: 0 .8rem 1rem #00000080;
}

.testimonial__person {
  justify-content: baseline;
  align-items: center;
  max-width: 24rem;
  padding: 1.2rem;
  display: flex;
}

.testimonial__image-container {
  border-radius: 50%;
  max-width: 8rem;
  max-height: 8rem;
  margin-left: 0;
  overflow: hidden;
}

.testimonial__image {
  object-fit: cover;
  width: 100%;
}

.testimonial__content-profession {
  flex-direction: column;
  display: flex;
}

.testimonial__name {
  margin-left: 0;
  font-size: 1.8rem;
  font-weight: bold;
}

.testimonial__work {
  margin-left: 0;
  font-size: 1.2rem;
}

.testimonial__comment {
  font-size: 1.7rem;
}

.quote__icon {
  color: var(--primary-color);
  font-size: 4rem;
}

.testimonial {
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-self: center;
  column-gap: 2.4rem;
  padding-top: 4.8rem;
  display: grid;
}

/*# sourceMappingURL=index.aa8744b4.css.map */
